







import { defineComponent } from 'vue-demi';
import CertificatesTable from '../components/CertificatesTable.vue';

export default defineComponent({
  components: { CertificatesTable },
});
