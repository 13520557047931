


















import { defineComponent, ref } from '@vue/composition-api';
import { api } from '@/api';
import { db } from '@/db';

export default defineComponent({
  props: {
    fileName: {
      type: String,
      required: true,
    },

    value: {
      type: String,
      default: '',
    },
  },

  setup(props, { emit }) {
    const inputRef = ref<HTMLInputElement | null>(null);
    const selectedFile = ref<File>();
    const error = ref<Error>();
    const uploading = ref(false);
    const progress = ref(0);

    async function startUpload() {
      uploading.value = true;
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await api.parts.upload(props.fileName, selectedFile.value!, (ratio) => {
          progress.value = Math.round(ratio * 100);
        });
        emit('input', props.fileName);
      } catch (err) {
        error.value = err;
      } finally {
        uploading.value = false;
      }
    }

    function onSelectFile() {
      if (inputRef.value) {
        inputRef.value.click();
      }
    }

    function onFileChange(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files && input.files.length) {
        selectedFile.value = input.files[0];
        startUpload();
      }
    }

    async function onOpenFile() {
      const item = await db.files.get(props.value);
      const url = URL.createObjectURL(item?.file);
      window.open(url, '_blank');
    }

    return {
      inputRef,
      onFileChange,
      onSelectFile,
      uploading,
      progress,
      onOpenFile,
    };
  },
});
