






















































































































































import { defineComponent, ref, reactive, watch, toRaw } from 'vue-demi';
import { Certificate } from '@project/shared';
import { partsDb } from '../PartsDb';
import { asyncComputed, debouncedWatch } from '@vueuse/core';
import { useStore } from '@/store';
import FileUpload from '@/components/FileUpload.vue';
import RequireOnline from '@/components/RequireOnline.vue';
import Confirm from '@/components/Confirm.vue';
import { db } from '../AppDb';
import { api } from '../../api';

type CertificateWithDocUrl = Certificate & {
  _docUrl?: string;
};

export default defineComponent({
  components: {
    FileUpload,
    RequireOnline,
    Confirm,
  },

  setup() {
    const mainStore = useStore();

    const headers = ref([
      {
        text: 'Code AMOS',
        value: 'codeAmos',
        width: '100px',
      },
      {
        text: 'Desc',
        value: 'description',
        width: '50%',
      },
      {
        text: 'Comment',
        value: 'comment',
        width: '40%',
      },
      {
        text: 'Doc',
        value: 'doc',
        width: '50px',
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
        width: '40px',
      },
    ]);

    const items = ref<CertificateWithDocUrl[]>([]);
    const searchValue = ref('');
    const query = reactive({
      search: '',
    });

    debouncedWatch(
      searchValue,
      () => {
        query.search = searchValue.value || '';
      },
      { debounce: 400 },
    );

    function setupDocUrl(item: CertificateWithDocUrl) {
      return partsDb
        .getFile(`pdf/${item.doc}`)
        .then((file) => {
          if (file && !item._docUrl) {
            item._docUrl = `/pdfjs/web/viewer.html#file=${URL.createObjectURL(file)}&name=${
              item.doc
            }`;

            items.value.splice(
              items.value.findIndex((row) => row.docRef === item.docRef),
              1,
              item,
            );
          }
        })
        .catch(() => {
          /* Silent error */
        });
    }

    async function updateItems() {
      // items.value = await certificates.search(query.search);
      items.value = await db.certificates.toArray();

      if (query.search.trim()) {
        const search = query.search.toLowerCase();

        items.value = items.value.filter((item) => {
          return (
            item.codeAmos.toLowerCase().includes(search) ||
            item.description?.toLowerCase().includes(search) ||
            item.comment?.toLowerCase().includes(search)
          );
        });
      }

      for (const item of items.value) {
        if (item.doc) {
          setupDocUrl(item);
        }
      }
    }

    watch(query, updateItems, { deep: true });

    updateItems().catch(console.error);

    /**
     * Edit stuff
     */
    const isNew = ref(false);
    const docRefErrors = ref<string[]>([]);
    const showEditDialog = ref(false);
    const loading = ref(false);
    const formRef = ref<{
      validate(): void;
    } | null>(null);

    const values = reactive<CertificateWithDocUrl>({
      docRef: '',
      codeAmos: '',
      description: '',
      comment: '',
      _docUrl: '',
    });

    const validForm = ref(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function copyFields(src: any, dest: any) {
      for (const key in dest) {
        dest[key] = src[key];
      }
    }

    async function onClickEdit(item: Partial<Certificate>) {
      isNew.value = item.docRef ? false : true;
      copyFields(item, values);

      if (values.codeAmos) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        values.codeAmos = (await db.parts.get(values.codeAmos))?.data;
      }

      showEditDialog.value = true;
    }

    const showFileDialog = ref(false);
    const currentFileParent = ref<Certificate>();
    const currentFileName = ref('');
    const fileValue = ref('');

    async function onSubmitItem() {
      if (isNew.value && (await db.certificates.get(values.docRef))) {
        docRefErrors.value = ['This certificate already exists'];
        return;
      }

      const item = { ...toRaw(values) };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (values.codeAmos) item.codeAmos = (values.codeAmos as any).codeAmos;

      loading.value = true;
      try {
        await api.certificates.save(item);
        await db.certificates.put(item);
        await updateItems();
        showEditDialog.value = false;
      } finally {
        loading.value = false;
      }
    }

    const searchPart = ref('');
    const partsItems = asyncComputed(async () => {
      const results = [
        ...(await partsDb.search(searchPart.value, { noSubLevel: true })).slice(0, 50),
      ];

      return results.map((item) => ({
        text: item.codeAmos,
        value: item,
      }));
    }, []);

    function onEditFile(item: Certificate) {
      currentFileParent.value = item;
      if (item.doc) {
        currentFileName.value = fileValue.value = `pdf/${item.doc}`;
      } else {
        currentFileName.value = `pdf/Certificate_${item.docRef}.pdf`;
        fileValue.value = '';
      }
      showFileDialog.value = true;
    }

    async function onFileChanged(fileName: string) {
      if (currentFileParent.value) {
        currentFileParent.value.doc = fileName.replace('pdf/', '');
        await api.certificates.save(currentFileParent.value);
        await db.certificates.put({ ...toRaw(currentFileParent.value) });
        setupDocUrl(currentFileParent.value);
      }
      showFileDialog.value = false;
    }

    /**
     * Delete stuff
     */
    const deleteItem = ref<Certificate>();
    const deleteShow = ref(false);

    function onDeleteItem(item: Certificate) {
      deleteItem.value = item;
      deleteShow.value = true;
    }

    async function onDeleteItemConfirm() {
      if (deleteItem.value) {
        const certif = deleteItem.value;

        const index = items.value.findIndex((item) => item.docRef === certif.docRef);
        await api.certificates.delete(certif.docRef);
        await db.certificates.delete(certif.docRef);
        if (index >= 0) items.value.splice(index, 1);
        deleteShow.value = false;
      }
    }

    function onClickItem(item: Certificate) {
      mainStore.state.showSearchPart = item.codeAmos;
    }

    return {
      headers,
      items,
      query,
      searchValue,

      showEditDialog,
      onClickEdit,

      formRef,
      values,
      docRefErrors,
      validForm,
      loading,
      onSubmitItem,
      isNew,

      searchPart,
      partsItems,

      currentFileName,
      fileValue,
      showFileDialog,
      onEditFile,
      onFileChanged,

      deleteItem,
      deleteShow,
      onDeleteItem,
      onDeleteItemConfirm,

      hasRoles: mainStore.hasRoles,

      onClickItem,
    };
  },
});
